@import '../../../variables';

.ChatWindow {
  position: relative;
  height: 100%;

  &__content {
    position: relative;
    height: 100%;
    overflow: auto;
  }

  &__messages {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    padding: 20px;
  }
}
