@import '../../../variables';

.ReviewerFilters {
  @include breakpoint(small only) {
    margin-bottom: 8px;

    .ReviewerFilters-FilterContent {
      display: none;
    }

    &.open {
      border: 2px solid map-get($vintro-colors, primary);
      border-radius: 4px;

      .ReviewerFilters-FilterContent {
        display: block;
      }

      .ReviewerFilters-FilterHeader {
        border-radius: 0;
        margin-bottom: 0;
      }
    }

    .ReviewerFilters-FilterHeader {
      background: map-get($vintro-colors, primary);
      border-radius: 4px;
      padding: 16px 8px;
      margin-bottom: 8px;
    }

    h6.Heading {
      margin-bottom: 0;
      color: #fff;

      i {
        position: absolute;
        right: 0;
        font-weight: 500;
      }
    }
  }

  h6.Heading {
    margin-top: 2px;
    font-weight: bold;
    position: relative;

    @include breakpoint(medium) {
      color: map-get($vintro-colors, secondary);
      margin-bottom: 26px;
      display: none;
    }
  }

  .ReviewerFilters-FilterGroup {
    border-top: 1px solid map-get($vintro-colors, input-border);
    padding: 16px 8px;

    h5.Heading {
      color: darken(map-get($vintro-colors, secondary), 10);
    }

    &.open {
      // background: lighten(map-get($vintro-colors, card-background), 1);
      background: #fbfbfb;

      h5.Heading {
        margin-bottom: 8px;
      }

      .Tags {
        span {
          border: 1px solid #efefef;
        }
      }
    }

    &__name {
      position: relative;
      cursor: pointer;
      padding-right: 16px;

      i {
        position: absolute;
        top: 0;
        right: 2px;
        color: map-get($vintro-colors, input-border);
        font-size: 12px;
      }
    }
  }

  .ReviewerFilters-FilterGroup--fixed {
    position: relative;

    &.open {
      .ReviewerFilters-FilterGroup__filters {
        margin-top: 44px;
        height: 300px;
        overflow-y: scroll;
      }

      .ReviewerFilters-FilterGroup__name {
        position: absolute;
        top: 0;
        left: 0;
        background: #eef1f5;
        width: 100%;
        padding: 16px 8px 8px 8px;
        border-bottom: 1px solid map-get($vintro-colors, input-border);

        i {
          top: 16px;
          right: 12px;
        }
      }
    }
  }

  .Spaced {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .Field--checkbox input {
    width: 16px;
    height: 16px;
    border: 1px solid #e2e2e2;
    background: white;

    &:after {
      width: 10px;
      height: 10px;
    }
  }

  .Field--text input {
    padding: 8px 16px;
  }

  .Field--select select {
    padding: 8px 1.5rem 8px 0.725rem;
  }

  .Field--checkbox__text {
    font-size: 14px;
    color: map-get($vintro-colors, secondary);
  }

  .Input--spaced {
    margin-bottom: 12px;
  }

  .TagCategory {
    display: inline-block;
    margin-left: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    color: darken(#476585, 20);
  }
}
