@import '../../variables';

.Checkout {
  &__totals {
    h1.Heading {
      margin-bottom: 32px;
      font-size: 40px;
      line-height: 47px;
    }
  }
}

.EmptyCart {
  text-align: center;

  img {
    max-width: 70%;
    margin: 0;
  }

  .callout {
    background-color: map-get($vintro-colors, card-background);
    padding: 60px;
    border-radius: 4px;
  }

  h2.Heading {
    margin: 0;
  }

  .Button {
    margin: 16px 0;
  }
}
