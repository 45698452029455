@import '../../../variables';

.SiteFooter {
  $c: &;
  margin-top: 40px;
  background: #f8f8f8;
  padding: 40px 0;
  border-top: 1px solid lighten($border-color, 10%);
  flex-shrink: 0;

  &__heading {
    font-weight: 600;
  }

  &__linkList {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__links {
    @include breakpoint(medium down) {
      margin-bottom: 20px;
    }
  }
}
