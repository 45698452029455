@import '../../../variables';

.Pagination {
  display: inline-block;
  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    position: relative;
    left: 1px;
  }

  &__item {
    min-width: 30px;
    display: inline-flex;
    height: 30px;
    align-items: center;
    justify-content: center;
    background: #fff;
    font-size: 14px;
    padding: 0 10px;
    cursor: pointer;
    border: 1px solid $border-color;
    margin-left: -1px;
    position: relative;
    border: none;
    font-family: proxima-nova, sans-serif;
    font-weight: 600;
    color: map-get($vintro-colors, secondary);

    &--previous,
    &--next {
      padding: 0 20px;

      &--disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    &:first-child {
      border-radius: 3px 0 0 3px;
    }

    &:last-child {
      border-radius: 0 3px 3px 0;
    }

    &--current {
      background: map-get($vintro-colors, card-background);
      color: map-get($vintro-colors, primary);
      z-index: 2;
      border-color: map-get($vintro-colors, card-background);
      border: none;
      border-radius: 50%;
    }
  }
}
