@import '../../../../variables';
@import '~foundation-sites/scss/foundation';

select {
  margin: 0 !important;
  height: auto !important;
}

input[type='text'] {
  height: auto;
}

.Input {
  &--spaced {
    margin-bottom: 30px;

    @include breakpoint(small only) {
      margin-bottom: 20px;
    }
  }

  input,
  select {
    border: 1px solid map-get($vintro-colors, input-border);
    border-radius: 4px;
    display: block;
    margin: 0;
    // color: map-get($vintro-colors, input-placeholder);
  }

  input,
  select,
  textarea {
    &::placeholder {
      color: map-get($vintro-colors, input-placeholder);
    }
  }

  select {
    &:disabled {
      background-color: none;
      opacity: 0.7;
    }
  }

  input,
  select,
  textarea {
    color: map-get($vintro-colors, input);
    font-family: proxima-nova, sans-serif;
  }

  select {
    color: map-get($vintro-colors, input-placeholder);
  }

  input {
    height: auto;
  }

  &--invalid {
    input,
    select,
    textarea {
      border-color: $alert-color;
      // background-color: lighten($alert-color, 43%);
      color: $alert-color;
    }
  }

  &--valid {
    input,
    select,
    textarea {
      border-color: $success-color;
      background-color: lighten($success-color, 58%);
      color: $success-color;
    }
  }

  textarea {
    resize: none;
    height: 150px;
    border: 1px solid $border-color;
  }

  select {
    height: auto;
  }

  &__helpText {
    font-weight: 600;
    font-size: 11px;
    margin-top: 4px;
    font-family: proxima-nova, sans-serif;
    color: #052138;
  }

  &__helpTextTop {
    font-weight: 500;
    font-size: 12px;
    margin-top: 4px;
    margin-bottom: 8px;
    font-family: proxima-nova, sans-serif;
    color: #052138;
  }

  &__label {
    font-weight: 600;
    font-size: 11px;
    margin-top: 4px;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.Field {
  select {
    color: map-get($vintro-colors, input-placeholder);
    background-size: auto;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDI0IDI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48IS0tR2VuZXJhdGVkIGJ5IElKU1ZHIChodHRwczovL2dpdGh1Yi5jb20vaWNvbmphci9JSlNWRyktLT48cGF0aCBkPSJNNy40MSw3Ljg0bDQuNTksNC41OGw0LjU5LC00LjU4bDEuNDEsMS40MWwtNiw2bC02LC02WiIgZmlsbD0iIzlBQTNDNCI+PC9wYXRoPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0wLC0wLjc1aDI0djI0aC0yNFoiPjwvcGF0aD48L3N2Zz4=');
    &.select--has-value {
      color: map-get($vintro-colors, input);
    }

    &:disabled {
      background-color: transparent;
      opacity: 0.7;
    }
  }
}
