@import '../../variables';

.ApplyWizard {
  .Spaced {
    margin-bottom: 30px;
  }

  #ApplyPayments {
    iframe {
      height: 740px;
      border: none;
      box-shadow: none;
      border-radius: 4px;
      border: 1px solid map-get($vintro-colors, input-border);
    }
  }
}

.Spaced {
  margin-bottom: 30px;
}
