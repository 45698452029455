.alert.callout {
  padding: 8px;
  color: #c05353;
  background: #c0535308;
  border: 2px solid #c05353;
  border-radius: 4px;
  margin-bottom: 8px;

  p {
    margin: 0;
  }
}
