@import '../../../variables';

.ChatContext {
  height: 80px;
  padding: 20px;
  border-bottom: 1px solid map-get($vintro-colors, input-border);
  background: map-get($vintro-colors, card-background);

  img {
    width: 40px;
    height: 40px;
  }
}
