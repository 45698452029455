@import '../../variables';

// $background-color: #f6f7fe;
$background-color: #fff;

.Cart {
  background: transparent;
  height: 102px;
  display: flex;
  padding: 0 0 0 20px;
  align-items: center;
  position: relative;

  &__panel {
    position: absolute;
    width: 400px;
    top: 108px;
    background: $background-color;
    right: 0;
    // padding: 10px;
    box-shadow: 0 20px 40px 0 rgba(25, 40, 94, 0.25);
    z-index: 9999;
    border-radius: 4px;
    transform: translateY(-20px);
    transition: transform 0.3s;
  }

  &__panelContent {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
  }

  &__item {
    border-bottom: 1px solid #efefef;
    margin-bottom: 20px;
    padding-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .Paragraph {
      margin: 0;
    }
  }

  &__items {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__actions {
    margin-top: 20px;
  }

  &__photo {
    width: 75px;
    height: 50px;
    border-radius: 4px;
  }

  &__photo_background {
    width: 75px;
    height: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    // background-position: center;
    border-radius: 4px;
  }

  h6.Heading {
    color: map-get($vintro-colors, paragraph);
    font-size: 16px;
    font-weight: 600;
    line-height: 17px;
    margin-bottom: 0;
  }

  .StrongText {
    color: map-get($vintro-colors, paragraph);
    font-size: 16px;
    font-weight: 600;
    line-height: 17px;
    margin-bottom: 0;
    display: block;
  }

  .Button--cart {
    border-radius: 10000px;
    background: transparent;
    border: 1px solid map-get($vintro-colors, input-border);
    color: $primary-color;
  }

  &--isOpen {
    .Button--cart {
      // background-color: #f6f7fe;
      // border-color: #f6f7fe;
      background-color: lighten(map-get($vintro-colors, input-border), 8);
      border-color: lighten(map-get($vintro-colors, input-border), 8);
    }

    .Cart__panel {
      transform: translateY(0px);
    }
  }
}

.PrimaryNav.PrimaryNav--mobile {
  .Controls {
    justify-content: center;
  }

  .Cart {
    height: auto;
    display: inline-flex;

    padding: 0 8px;
    height: 24px;

    .Button--cart {
      font-size: 12px;

      padding: 8px;
    }

    &__panel {
      top: 58px;
      right: -24px;
      width: calc(100vw - 0.625rem * 2);
    }
  }
}
