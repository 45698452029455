@import '../../../../variables';

.Heading {
  font-weight: 500;
}
h1.Heading {
  color: $primary-color;
  font-size: 30px;
  line-height: 37px;
  font-weight: 500;

  &--collapsed {
    margin: 0;
  }
}

h3.Heading {
  color: $primary-color;
}

h2.Heading {
  line-height: 1;
  color: map-get($vintro-colors, secondary);
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 30px;
}

h5.Heading {
  color: map-get($vintro-colors, paragraph);
  font-weight: 600;
  font-size: 14px;
  margin: 0;
}

h6.Heading {
  font-weight: 500;
  color: map-get($vintro-colors, secondary);
  margin-bottom: 30px;

  &--collapsed {
    margin: 0;
  }
}
