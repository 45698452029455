@import '../../variables';

.ReviewerIndustries {
  font-weight: 600;
  font-family: proxima-nova, sans-serif;
  color: map-get($vintro-colors, secondary);
  font-size: 14px;
}

.ReviewerDetail {
  h1.Heading {
    color: map-get($vintro-colors, primary);
    font-size: 28px;
    font-weight: bold;
    line-height: 32px;
    margin: 0;

    @include breakpoint(small only) {
      margin-top: 32px;
    }
  }

  h2.Heading {
    color: map-get($vintro-colors, secondary);
    font-size: 16px;
    line-height: 20px;
  }

  h5.Heading {
    margin-bottom: 16px;
  }

  &__charityName {
    color: map-get($vintro-colors, primary);
  }

  .StarRating__star {
    margin-bottom: 0;
    font-size: 12px;
  }

  .Stat {
    h6.Heading {
      color: map-get($vintro-colors, primary);
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0;
    }
  }

  .ReviewerCard__image--mobile {
    display: none;
    position: relative;
    @include breakpoint(small only) {
      display: block;
      margin-bottom: 16px;
    }
  }

  .ReviewerPhoto__logo {
    left: 20px;
    bottom: -30px;
    width: 80px;
    height: 80px;
  }

  .ReviewerCard__image--default {
    position: relative;
    width: 396px;
    height: 254px;

    .ReviewerCard__logo {
      top: 350px;
    }

    @include breakpoint(small only) {
      display: none;
    }
  }

  header {
    @include breakpoint(small only) {
      .Button {
        width: 100%;
        margin: 0 0 16px 0;
      }
    }
  }

  &__flag {
    span {
      font-size: 26px;
      margin-right: 15px;
      background-size: 42px !important;
      transform: rotate(-20deg);
      border-radius: 50%;
    }
    display: inline-flex;
    align-items: center;
    font-weight: 500;
  }

  &__companyLogo {
    margin-bottom: 20px;
  }

  &__typeList {
    font-size: 14px;
    padding: 0;
    margin: 0 0 20px 0;
    list-style: none;

    li {
      font-weight: 400;
      color: map-get($vintro-colors, secondary);
      line-height: 1;
      margin-bottom: 8px;
    }

    i {
      color: map-get($vintro-colors, primary);
      display: inline-block;
      margin-right: 5px;
    }
  }

  &__tag {
    background: map-get($vintro-colors, card-background);
    padding: 4px 8px;
    border-radius: 3px;
    font-size: 13px;
    margin-right: 8px;
    margin-bottom: 8px;
    display: inline-block;
    font-weight: 600;
    font-family: proxima-nova, sans-serif;
    color: map-get($vintro-colors, secondary);
  }

  &__mainHeading {
    font-weight: 600;
    font-size: 32px;
    color: map-get($vintro-colors, primary);
  }

  &__companyInfo {
    font-weight: 500;
    font-size: 18px;
    color: map-get($vintro-colors, secondary);
  }

  &__content {
    margin-top: 20px;
  }

  &__header {
    background: #f8f8f8;
    margin-top: -60px;
    padding-top: 80px;

    @include breakpoint(small only) {
      padding-top: 40px;
    }
  }

  &__heading {
    font-size: 28px;
    font-weight: 500;
    color: $primary-color;
    margin: 20px 0 0 0;
  }

  &__subheading {
    font-weight: 500;
    color: map-get($vintro-colors, secondary);
    font-size: 15px;
    margin: 0 0 0 0;

    .ReviewerDetail__company {
      font-size: 18px;
    }

    .ReviewerDetail__title {
      margin: 0 0 20px 0;
      display: block;
    }
  }

  hr {
    margin: 0.5rem auto;
  }

  &__reviewerDescription {
    padding-bottom: 17px;
  }

  &__cta {
    background: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 30px;

    @include breakpoint(small only) {
      padding: 20px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      margin-bottom: 20px;
    }

    @include breakpoint(medium) {
      height: 100%;
    }
  }

  &__ctaButton {
    margin-bottom: 15px;
  }

  &__ctaDescription {
    color: map-get($vintro-colors, secondary);
    font-size: 13px;
    margin-bottom: 0;

    &--textRight {
      text-align: right;
    }
  }

  &__worldIcon {
    margin-right: 10px;
  }

  &__section {
    padding-top: 60px;

    @include breakpoint(small only) {
      padding: 40px 20px;
    }
  }

  &__sectionItem {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__sectionHeading {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    color: map-get($vintro-colors, primary);
    margin: 0 0 20px 0;
  }

  &__sectionSubheading {
    font-weight: 600;
    font-size: 16px;
    margin: 0 0 10px 0;
  }

  &__donationCta {
    background: #fde9ef;
    padding: 30px 30px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    text-align: center;

    @include breakpoint(small only) {
      margin: 10px 20px;
    }
  }

  &__donationCtaCell {
    @include breakpoint(small only) {
      order: -1;
    }
  }

  &__donationHeading {
    color: map-get($vintro-colors, primary);
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    margin-bottom: 30px;
  }

  &__donationName {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    svg {
      margin-right: 10px;
    }
  }

  &__donationText {
    color: map-get($vintro-colors, secondary);
    color: #962748;
    font-family: proxima-nova, sans-serif;
    margin: 0;
  }
}
