@import '../../../variables';

.Card {
  padding: 20px;

  // border: 1px solid $border-color;
  margin-bottom: 8px;
  border-radius: 4px;
  // box-shadow: 4px 4px 17px hsla(0, 0%, 85.9%, 0.5);
  transition: all 0.2s;

  // box-shadow: 0 3px 8px 0 rgba(23, 28, 33, 0.06);
  // border: 1px solid #f3f3f3;
  // box-shadow: 3px 3px 12px 0 rgba(23, 28, 33, 0.06);

  background: map-get($vintro-colors, card-background);

  &--transparent {
    background-color: transparent;
  }

  &--collapsed {
    padding: 0;
  }

  &--hover {
    &:hover {
      box-shadow: 4px 4px 17px hsla(0, 0%, 85.9%, 0.6);
      transform: translateY(-3px);
    }
  }

  &--dark {
    background: #eeeeee;
  }

  &--air {
    background: #fff;
    box-shadow: 3px 3px 12px 0 rgba(23, 28, 33, 0.06);
  }

  &--selectable {
    border: 4px solid transparent;
  }

  &--selected {
    border: 4px solid map-get($vintro-colors, primary);
  }

  &--full-height {
    height: 100%;
  }
}
