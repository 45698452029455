.Field--search {
  position: relative;
  input {
    padding-left: 48px;
  }

  img {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 14px;
    left: 12px;
  }
}
