.Price {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 15px;
  line-height: 1;

  &--large {
    font-size: 38px;
  }
}
