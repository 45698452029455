@import '../../../variables';

.Callout {
  background: lighten($light-gray, 4);
  padding: 60px 20px;
  border-radius: 4px;

  &--alignCenter {
    text-align: center;
  }

  &--alignLeft {
    text-align: left;
  }

  &--alignRight {
    text-align: right;
  }

  &--collapsed {
    padding: 0;
  }
}
