@import '../../../variables';

.CreateReviewer {
  textarea {
    height: 400px;
  }
}

.ReviewerStatus {
  span {
    background: map-get($vintro-colors, card-background);
    padding: 8px;
    border-radius: 4px;
    margin-right: 8px;
    font-weight: 600;
    font-family: proxima-nova, sans-serif;
    color: #476585;
    display: inline-block;

    a {
      color: #476585;
    }

    &.complete {
      background: map-get($vintro-colors, primary);
      color: #fff;

      a {
        color: #fff;
      }
    }
  }
}

.ReviewerTags {
  span {
    background: map-get($vintro-colors, card-background);
    padding: 8px 12px;
    border-radius: 4px;
    margin-right: 8px;
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 16px;
    font-family: proxima-nova, sans-serif;
    color: #476585;
    display: inline-block;

    &.complete {
      background: map-get($vintro-colors, primary);
      color: #fff;
    }
  }
}

.EditReviewer {
  h6.Heading {
    margin-bottom: 12px;
  }

  h4.Heading {
    color: darken(#476585, 10);
    margin-top: 0;
    margin-bottom: 0;
    font-size: 24px;
    line-height: 24px;
  }

  .Button {
    margin-left: 12px;
  }

  .AffiliateLink {
    .Button {
      margin-left: 0;
    }
  }

  .ReviewerDetail {
    .Container {
      padding: 0;
    }
  }
}
