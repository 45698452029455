@import '../../../variables';

.Image {
  &--round {
    border-radius: 50%;
  }

  &--radius {
    border-radius: $global-radius;
  }

  height: auto;
}
