@import '../../../variables';

.Divider {
  width: 100%;
  max-width: 100%;
  border-color: darken(map-get($vintro-colors, card-background), 10);
  border-width: 1px;

  &--collapsed {
    margin: 0;
  }
}
