@import '../../../variables';

.DashboardMenu {
  list-style: none;
  margin: 0;

  li {
    font-weight: 500;
    font-size: 16px;
    font-family: proxima-nova, sans-serif;
    color: map-get($vintro-colors, secondary);

    a {
      color: map-get($vintro-colors, secondary);

      &.active {
        color: $primary-color;
      }
    }

    &.active {
      color: $primary-color;
    }
  }
}

.DashboardMenu--Main {
  list-style: none;
  margin: 0;
  margin-bottom: 32px;

  li {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    font-family: proxima-nova, sans-serif;
    color: map-get($vintro-colors, secondary);

    a {
      color: map-get($vintro-colors, secondary);

      &.active {
        color: $primary-color;
      }
    }

    &.active {
      color: $primary-color;
    }
  }
}

.SecondaryNav {
  .Beacon {
    display: inline-block;
    margin: 0 4px;
    background: #ff5d5d;
    border-radius: 4px;
    font-size: 12px;
    line-height: 12px;
    color: #fff;
    padding: 4px 8px;
    margin-top: -4px;
    position: relative;
    top: -2px;
  }

  .BeaconAnimated {
    display: inline-block;
    margin-top: -8px;
  }
}
