.Checkout {
  .Cart__photo {
    width: 75px;
    height: 50px;
    border-radius: 4px;
  }

  .Cart__photo_background {
    width: 75px;
    height: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    // background-position: center;
    border-radius: 4px;
  }

  .Cart__item {
    h6.Heading {
      color: #19285e;
      font-size: 16px;
      font-weight: 600;
      line-height: 17px;
      margin-bottom: 0;
    }

    .StrongText {
      color: #19285e;
      font-size: 16px;
      font-weight: 600;
      line-height: 17px;
      margin-bottom: 0;
      display: block;
    }

    border-bottom: 1px solid #c6cce4;
  }

  .Cart__items {
    margin: 1rem 0;
  }

  &__billing {
    h4.Heading {
      color: #7480ae;
      font-size: 24px;
      line-height: 32px;
    }

    .Button {
      margin-bottom: 8px;
    }
  }
}
