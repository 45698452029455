@import '../../../../variables';

.intl-tel-input {
  width: 100%;
}

.react-tel-input input[type='text'],
.react-tel-input input[type='tel'] {
  border: 1px solid map-get($vintro-colors, input-border);
  border-radius: 4px;
  display: block;
  // margin: 0;
  height: 2.8875rem;
  // margin: 0 0 1rem;
  padding: 0.725rem 0.725rem 0.725rem 48px;
  width: 100%;

  background-color: #fefefe;
  box-shadow: none;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
}

.react-tel-input .flag-dropdown {
  background-color: #c7d5e3;
  border: 1px solid #c7d5e3;
}

.react-tel-input .selected-flag {
  background: lighten(#c7d5e3, 10%);
}
