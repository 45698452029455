@import '../../../variables';

.ReviewerCard {
  &__meta {
    width: 160px;
  }

  &__service {
    font-weight: bold;
    font-size: 16px;
    margin: 10px 0;
  }

  &__header {
    margin-bottom: 20px;
  }

  &__heading {
    font-size: 24px;
    font-weight: 500;
    margin-top: 8px;
    font-family: proxima-nova, sans-serif;
    color: map-get($vintro-colors, primary);
    color: map-get($vintro-colors, secondary);

    line-height: 22px;

    a {
      // color: map-get($vintro-colors, primary);
      color: darken(map-get($vintro-colors, secondary), 10);
      // color: darken(map-get($vintro-colors, primary), 10);
    }
  }

  &__subheading {
    margin-top: 2px;
    font-size: 16px;
    font-weight: 500;
    font-family: proxima-nova, sans-serif;
    color: lighten(map-get($vintro-colors, secondary), 1);
  }

  &__company {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.1;
    // color: darken(map-get($vintro-colors, primary), 5);
  }

  hr {
    margin: 0.5rem auto;
    border-bottom-color: lighten(map-get($vintro-colors, secondary), 53);
  }

  &__title {
    font-size: 15px;
  }

  &__action {
    margin-top: 0;
    margin-left: -20px;
    width: calc(100% + 40px);
    margin-bottom: -20px;

    .Button {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }

  &__image {
    margin: -20px -20px 0 -20px;
    width: calc(100% + 40px);
  }

  &__image__background {
    height: 300px;
    width: 100%;
    background-size: cover;
    // background-position: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    &--larger {
      height: 400px;
    }

    &--with-filters {
      height: 200px;
    }

    @include breakpoint(small only) {
      height: 320px;
    }
  }

  &__logo {
    position: absolute;

    top: 270px;
    right: 10px;

    @include breakpoint(small only) {
      top: 290px;
    }

    &--with-filters {
      top: 160px;
      right: 10px;

      @include breakpoint(small only) {
        top: 290px;
      }
    }
  }

  &__country {
    span {
      border-right: 1px solid #ced5dc;
      padding-right: 8px;
      display: inline-block;
      margin-right: 8px;
      height: 20px;
    }

    &--svg {
      span {
        border-right: none;
      }
    }
  }

  &__logo__background {
    height: 60px;
    width: 60px;
    background-size: contain;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 75%;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  }

  .ReviewerCard__list {
    font-weight: 600;
    font-family: proxima-nova, sans-serif;
    color: map-get($vintro-colors, secondary);
    font-size: 14px;

    span {
      background: #fff;
      padding: 4px 8px;
      border-radius: 10000px;
      font-size: 12px;
      margin-right: 4px;
      margin-bottom: 4px;
      display: inline-block;
    }
  }

  .StarRating__star {
    font-size: 10px;
  }

  &--compact {
    margin-bottom: 20px;
    height: calc(100% - 20px);
    position: relative;

    .ReviewerCard__image {
      // width: 100%;
      text-align: center;
    }

    .ReviewerCard__meta {
      width: calc(100% - 40px);
      position: absolute;
      bottom: 20px;
    }

    .ReviewerCard__purchase {
      height: 32px;
    }
  }

  .Button--disabled {
    background: #575b6b;
    color: #fff;
  }
}
