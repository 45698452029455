@import '../../../variables';

.AppError {
  text-align: center;

  img {
    max-width: 70%;
    margin: 0;
  }

  .callout {
    // background-color: map-get($vintro-colors, card-background);
    padding: 60px;
    border-radius: 4px;
  }

  h2.Heading {
    margin: 0;
  }
}
