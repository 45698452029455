@import '../../variables';

.Chat {
  &__content {
    background: #fff;
    height: calc(100vh - 224px);
    min-height: 600px;
    border: 1px solid $border-color;
    border-radius: $global-radius;
    overflow: hidden;
    position: relative;

    @include breakpoint(medium down) {
      min-height: 0;
      height: calc(100vh - 120px);
    }
  }

  &__display {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
