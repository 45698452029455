@import '../../../../variables';

.Field--checkbox {
  margin-bottom: 4px;

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    vertical-align: top;
    display: inline-block;
    margin: 1px 0 0 10px;
    font-family: Open sans, sans-serif;
    -webkit-font-smoothing: auto;
    color: #333;
  }

  input {
    appearance: none;
    padding: 0;
    margin: 0;
    width: 20px;
    height: 20px;
    position: relative;
    border-radius: 4px;
    // padding: 8px;

    &::after {
      width: 14px;
      height: 14px;
      display: inline-block;
      position: absolute;
      top: 2px;
      left: 2px;
      background: transparent;
      content: '';
      border-radius: 4px;
      transition: background 0.2s;
    }
  }

  input[type='checkbox']:checked {
    &::after {
      background: $primary-color;
    }
  }
}
