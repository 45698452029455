@import '../../../../variables';

.Field--password {
  &__toggle {
    font-weight: 600;
    font-size: 11px;
    cursor: pointer;
    color: #555;
    font-family: Open sans, sans-serif;
  }

  &__help {
    margin: 8px 0;
    display: block;
  }

  &__forget {
    font-weight: 600;
    font-size: 11px;
    cursor: pointer;
    color: map-get($vintro-colors, primary);
    font-family: Open sans, sans-serif;
  }
}
