@import '../../../../variables';

.Label {
  font-weight: 500;

  margin-bottom: 8px;
  font-family: proxima-nova, sans-serif;
  -webkit-font-smoothing: auto;
  color: map-get($vintro-colors, input-label);
  font-size: 16px;
  line-height: 18px;
}

.Help {
  font-family: proxima-nova, sans-serif;
  color: #052138;
  line-height: 1.4;
  font-size: 12px;
  margin-bottom: 8px;
}
