@import '../../../variables';

.ReviewerPhoto {
  position: relative;
  background-size: cover;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;

  &__logo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    right: 20px;
    bottom: -20px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  }

  @include breakpoint(small only) {
    height: 360px !important;
    margin-bottom: 40px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
