@import '../../../../variables';

.VideoInputContainer {
  @include breakpoint(small only) {
    text-align: center;
  }
}

.VideoInputWrapper {
  // border: 1px dashed #c6cce4;
  border-radius: 4px;

  &:focus {
    -webkit-tap-highlight-color: none;
    outline: none !important;
  }

  input:focus {
    outline: none !important;
  }
}

.VideoInput {
  background: map-get($vintro-colors, card-background);
  width: 100%;
  min-height: 360px;
  // border: 2px solid $light-gray;
  border-radius: 8px;
  // width: calc(358px / 1.2);
  // height: calc(640px / 1.2);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  padding: 32px 16px;
  margin-bottom: 16px;
  // border: 1px dashed #c6cce4;

  &__DurationVideo {
    display: none;
  }

  p {
    margin: 0;
    font-size: 14px;
  }

  input {
    appearance: none;
  }

  .Loader--show {
    display: inline-block;
  }

  .Loading {
    text-align: center;
  }

  .ProgressBar {
    border-radius: 4px;
    height: 4px;
    width: 200px;
    background: darken(map-get($vintro-colors, card-background), 10);
    position: relative;
    display: inline-block;
    margin: 12px 0;

    &__progress {
      border-radius: 4px;
      top: 0;
      left: 0;
      position: absolute;
      height: 4px;
      background: map-get($vintro-colors, primary);
      transition: width 0.1s;
    }
  }

  .Preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 360px;

    .PreviewImage {
      width: 138px;
      height: 138px;
      background-size: contain;
      background-position: center;
      border-radius: 50%;
      background-repeat: no-repeat;
      pointer-events: none;
    }

    .Loading {
      // display: inline-flex;
      text-align: center;
    }

    .LoadingMessage {
      width: 100%;
      display: inline-block;
      padding: 16px;
      font-family: Open Sans, sans-serif;
      color: #333;
      line-height: 1.4;
    }

    .Loader {
      display: inline-block;
      text-align: center;
    }

    video {
      padding: 0;
      display: inline-flex;
      // position: absolute;
      // left: 0px;
      // top: 0px;
      // width: calc(358px / 1.2);
      // height: calc(640px / 1.2);
      margin: 0;
      // object-fit: cover;
      border-radius: 4px;
    }
  }
}
