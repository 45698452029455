@import '../../../variables';

.Modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;

  &--background {
    background: rgba(255, 255, 255, 0.85);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &--content {
    min-height: 40vh;
    min-width: 40vw;
    background: #fff;
    position: relative;
    z-index: 999999;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 3px 3px 100px 60px rgba(148, 147, 147, 0.23);
    padding: 48px 48px;

    @include breakpoint(small only) {
      margin: 0 10px;
    }
  }
}
