// Foundation Base
@import '~foundation-sites/scss/foundation';

// Color Variables
// $primary-color: #4469ef;
// $primary-color: #7389ff;
$primary-color: #1266ad;
$secondary-color: salmon;
$tertiary-color: #666;
$alert-color: #e41d44;
$warning-color: rgb(187, 187, 104);
$success-color: rgb(59, 148, 59);
$border-color: #c6cce4;
$light-gray: #eeeeee;
$medium-gray: #cacaca;
$dark-gray: #8a8a8a;
$black: #0a0a0a;
$white: #fefefe;
$foundation-palette: (
  primary: $primary-color,
  secondary: $secondary-color,
  success: $success-color,
  warning: $warning-color,
  alert: $alert-color,
);

// Layout Variables
$global-radius: 4px;
$global-margin: 20px;

// Header Settings
$header-font-family: proxima-nova, sans-serif;
$header-font-weight: 300;

// Input Settings
$input-shadow: none;

$input-font-family: proxima-nova, sans-serif;
$input-padding: 0.725rem;

$input-border: 1px solid $border-color;
$input-border-focus: 1px solid $primary-color;

$hr-border: 1px solid #efefef;

// Button Settings
$button-radius: 4px;

/*
$vintro-colors: (
  primary: #4469ef,
  secondary: #7480ae,
  card-background: #f6f7fe,
  input-border: #c6cce4,
  input: #19285e,
  input-placeholder: #9aa3c4,
  input-label: #19285e,
  paragraph: #19285e,
);*/

/*
$vintro-colors: (
  primary: #1266ad,
  secondary: #476585,
  card-background: #f7f9fb,
  input-border: darken(#f7f9fb, 14),
  input: #052138,
  input-label: #052138,
  input-placeholder: lighten(#476585, 35),
  paragraph: #052138,
);*/

$vintro-colors: (
  primary: #005aa7,
  secondary: #476585,
  card-background: #f7f9fb,
  input-border: darken(#f7f9fb, 14),
  input: #052138,
  input-label: #052138,
  input-placeholder: lighten(#476585, 35),
  paragraph: #052138,
);

// #556383 another decent grey
