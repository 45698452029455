@import '../../../variables';

.Button {
  border: none;
  display: inline-block;
  padding: 18px 18px;
  background: #f5f5f5;
  // border: 1px solid #d8d8d8;
  border-radius: 4px;
  // border: 1px solid;
  outline: none;
  cursor: pointer;
  text-align: center;
  font-size: 1em;
  font-family: proxima-nova, sans-serif;
  font-weight: bold;

  // Themes
  &--primary {
    background: $primary-color;
    color: #fff;
    border-color: darken($primary-color, 10%);

    .Loader {
      border-color: darken($primary-color, 10%);
      border-top-color: #fff;
    }
  }

  &--primary--hollow {
    background: transparent;
    border-radius: 2px;
    border: 1.5px solid $primary-color;
    color: $primary-color;
    border-color: $primary-color;
    padding: 16px 18px;
  }

  &--secondary {
    background: $secondary-color;
    color: #fff;
    border-color: darken($secondary-color, 10%);
  }

  &--tertiary {
    background: $tertiary-color;
    color: #fff;
    border-color: darken($tertiary-color, 10%);
  }

  &--alert {
    background: $alert-color;
    color: #fff;
    border-color: darken($alert-color, 10%);
  }

  &--success {
    background: $success-color;
    color: #fff;
    border-color: darken($success-color, 10%);
  }

  &--warning {
    background: $warning-color;
    color: #fff;
    border-color: darken($warning-color, 10%);
  }

  // Sizes
  &--large {
    font-size: 1.3em;
    padding: 15px 30px;
  }

  &--small {
    font-size: 0.8em;
  }

  &--tiny {
    font-size: 0.6em;
  }

  // Layout
  &--full {
    display: block;
    width: 100%;
  }

  &--hollow {
    background: transparent;
    // color: #c6cce4;
    // border: 1.5px solid #c6cce4;
  }

  // Other states
  &--disabled {
    background: #f5f5f5;
    border-color: darken(#f5f5f5, 10%);
    cursor: not-allowed;
    color: #666;
  }
}

.Button--Loading {
  // display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  position: relative;

  .Loader {
    display: inline-block;
    height: 14px;
    width: 14px;
    margin-right: 8px;
    border-width: 2px;
    border-top-width: 2px;
  }

  &__Loader {
    width: 14px;
    height: 14px;
    margin-right: 8px;
    overflow: hidden;
    display: inline-block;
    position: absolute;
    left: 0px;
    top: 2px;
  }

  &__LoaderContainer {
    padding-right: 20px;
  }
}
