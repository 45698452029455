@import '../../../variables';

.ChatMessage {
  $c: &;
  margin-bottom: 20px;
  display: flex;

  &__content {
    display: inline-flex;
    flex-direction: column;
    max-width: 55%;

    @include breakpoint(medium only) {
      max-width: 65%;
    }

    @include breakpoint(small only) {
      max-width: 90%;
    }
  }

  &__bubble {
    background: #eee;
    padding: 20px;
    border-radius: 5px;
    position: relative;

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #eee;
      position: absolute;
      bottom: -8px;
      left: 20px;
      display: block;
    }
  }

  &__timestamp {
    font-weight: 500;
    font-size: 13px;
    margin: 20px 0 0 0;
    color: darken(#eee, 50%);
  }

  &--isOwned {
    justify-content: flex-end;

    #{$c}__timestamp {
      text-align: right;
    }

    #{$c}__bubble {
      background: $primary-color;
      color: #fff;

      &:before {
        border-top-color: $primary-color;
        left: initial;
        right: 20px;
      }
    }
  }
}
