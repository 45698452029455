@import '../../../variables';

.CheckoutItem {
  padding: 20px 0 20px 0;
  border-bottom: 1px solid #efefef;

  &__image {
    width: 80px;
  }

  &__button {
    width: 20px;
    height: auto;
    cursor: pointer;
    outline: 0;

    .Loader {
      width: 20px;
      height: 20px;
      border-top-width: 2px;
      border-width: 2px;
    }
  }

  &__photo {
    width: 112px;
    height: 75px;
    border-radius: 4px;
  }

  &__photo_background {
    width: 112px;
    height: 75px;
    background-size: cover;
    background-repeat: no-repeat;
    // background-position: center;
    border-radius: 4px;
  }

  svg {
    .a {
      fill: none;
      stroke: #9aa3c4;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 1.5px;
    }
  }

  .StarRating__star {
    font-size: 10px;
  }

  .StrongText {
    color: map-get($vintro-colors, paragraph);
    font-size: 16px;
    line-height: 20px;
    display: block;
  }

  h2.Heading {
    margin-bottom: 4px;
    line-height: 1;
    color: map-get($vintro-colors, primary);
  }

  h3.Heading {
    color: map-get($vintro-colors, secondary);
    font-size: 16px;
    line-height: 20px;
  }
}
