@import '../../variables';

.MyOrders {
  &--card {
    h6.Heading {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 600;
      color: map-get($vintro-colors, paragraph);
    }

    h5.Heading {
      font-weight: 500;
      color: map-get($vintro-colors, secondary);
    }
  }
}
