@import 'variables';

// Global Styles
@include foundation-flex-classes;
@include foundation-global-styles;
@include foundation-typography;
@include foundation-xy-grid-classes;
@include foundation-forms;
@include foundation-typography;

body {
  overflow-x: hidden;
}

// Utilities
.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.spaced {
  margin-bottom: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;

  &--margin {
    margin-bottom: 60px;
  }

  &--bordered {
    tr {
      border-bottom: 1px solid $border-color;
    }
  }

  tr.total {
    border-top: 1px solid black;
  }

  td {
    vertical-align: top;
    padding: 8px 0;
  }

  .align-right {
    text-align: right;
  }

  .align-left {
    text-align: left;
  }
}

[type='submit'],
[type='button'] {
  border-radius: 4px;
}

textarea {
  border-radius: 4px;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__content {
    flex: 1 0 auto;
  }
}
