@import '../../../../variables';

.SelectSort {
  .Field {
    select {
      background-position: right -1.5rem center;
      width: 100%;
      border: none;
      font-weight: 500;
      color: map-get($vintro-colors, secondary);
      margin: 0;
      // margin-bottom: 30px !important;
      display: inline-block;
      padding: 0 2rem;
      text-align-last: right;
    }

    select:focus {
      box-shadow: none;
    }
  }
}
