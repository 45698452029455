@import '../../../variables';

.ChatInstance {
  padding: 20px;
  position: relative;
  border-bottom: 1px solid map-get($vintro-colors, input-border);
  background: darken(map-get($vintro-colors, card-background), 2);
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: darken(#f5f5f5, 3%);
  }

  &__image {
    margin-right: 20px;
    position: relative;

    img {
      width: 50px;
      height: 50px;
    }
  }

  &__heading {
    font-weight: 600;
    font-size: 14px;
    color: #606161;
  }

  &__description {
    margin: 0;
    font-size: 12px;
    color: #606161;
  }

  &__indicator {
    position: absolute;
    left: -5px;
    top: -5px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $alert-color;
    border: 1px solid darken($alert-color, 8%);
    color: #fff;
    font-weight: 600;
    font-size: 11px;
    border-radius: 50%;
  }

  &--isActive {
    background: #fff;

    &:after {
      content: '';
      width: 1px;
      height: 100%;
      position: absolute;
      right: -1px;
      background: #fff;
      top: 0;
    }
  }
}
