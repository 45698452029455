@import '../../../variables';

.Notifications {
  position: fixed;
  top: 100px;
  left: 0px;
  // pointer-events: none;
  width: 100%;
  z-index: 9999;
  transform: translateY(-50px);
  transition: all 0.3s;

  @include breakpoint(small only) {
    top: 60px;
  }

  &--show {
    transform: translateY(0px);
  }

  &__banner {
    padding: 20px;
    width: 100%;
    color: #fff;
    font-family: proxima-nova, sans-serif;
    font-size: 16px;
    font-weight: 600;
    background-color: $primary-color;
    box-shadow: 0 20px 40px 0 rgba(25, 40, 94, 0.25);
  }

  &__error {
    background-color: #e41d44;
  }

  &__confirm {
    background-color: #f7f9fb;
    color: map-get($vintro-colors, primary);

    .Button {
      margin-left: 8px;
    }
  }
}
