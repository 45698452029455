@import '../../../variables';

.Tabs {
  &__list {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__header {
    display: inline-flex;
    height: 40px;
    background: transparent;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
    margin-bottom: -1px;
    position: relative;
    border: 1px solid transparent;
    cursor: pointer;
    border-radius: 3px 3px 0 0;
    border-bottom: none;
    font-weight: 600;

    &--active {
      color: $primary-color;
      border-color: $border-color;
      background: #fff;
    }
  }

  &__panel {
    display: none;
    background: #fff;
    border: 1px solid $border-color;
    border-radius: 0 3px 3px 3px;
    padding: 20px;

    &--active {
      display: block;
    }
  }
}
