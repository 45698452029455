@import '../../variables';

.MyOrder {
  &--card {
    &__photo {
      width: 100%;
      height: 130px;
      border-radius: 4px;
      margin-bottom: 8px;
    }

    &--pending {
      opacity: 0.5;
    }

    &__photo_background {
      width: 100%;
      height: 130px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 4px;
    }

    h2.Heading {
      margin: 0;
      color: map-get($vintro-colors, primary);
    }

    &__controls {
      margin: 20px -20px -20px -20px;
      background: #e8edf3;
      padding: 16px 16px;
      border-radius: 4px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      .StarRating__star {
        font-size: 20px;
      }
    }

    .StarRating__wrapper {
      display: inline-block;
    }

    .ReviewerCard {
      .Card {
        padding: 0;
      }

      .ReviewerCard__image {
        margin: 0;
        width: 100%;
      }
    }
  }
}
