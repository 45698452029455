@import '../../../variables';

.AdminOrder {
  .Button {
    margin-left: 16px;
  }

  h1.Heading {
    margin: 0;
  }

  .Cart__item {
    h6.Heading {
      font-size: 20px;
      font-weight: 600;
      margin: 0;
    }

    border-bottom: none;
  }
}

.AdminOrders {
  &__Card {
    h1.Heading {
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .Reviewers__List {
      font-weight: 600;
      font-family: proxima-nova, sans-serif;
      color: map-get($vintro-colors, secondary);
      font-size: 14px;

      span {
        background: #fff;
        padding: 4px 8px;
        border-radius: 10000px;
        font-size: 12px;
        margin-right: 4px;
        margin-bottom: 4px;
        margin-top: 4px;
        display: inline-block;
      }
    }
  }
}
