.StarRating {
  display: inline-flex;

  &__star {
    margin-right: 5px;
    font-size: 14px;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #ff5d5d;
    margin-bottom: 8px;

    &:last-child {
      margin: 0;
    }

    &--active {
      color: #ff5d5d;
    }

    &--isClickable {
      cursor: pointer;
    }
  }
}
