@import '../../../variables';

.ChatMessageForm {
  background: map-get($vintro-colors, card-background);
  border-top: 1px solid map-get($vintro-colors, input-border);
  padding: 20px;

  input,
  button {
    margin: 0;
  }

  input[type='text'] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:focus {
      border-color: $border-color;
      box-shadow: none;
    }
  }

  &__button {
    background: #fff;
    color: $primary-color;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    border-radius: 0 3px 3px 0 !important;
    height: 49px;
    border: 1px solid $border-color;
    border-left: none;
  }
}
