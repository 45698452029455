@import '../../../variables';

.Tags {
  font-weight: 600;
  font-family: proxima-nova, sans-serif;
  color: map-get($vintro-colors, secondary);
  font-size: 14px;

  span {
    background: #fff;
    padding: 4px 8px;
    border-radius: 10000px;
    font-size: 12px;
    margin-right: 4px;
    margin-bottom: 4px;
    display: inline-block;
  }
}

.Tag {
  &.selected {
    color: #fff;
    background: #1779ba;
  }

  &.selectable {
    cursor: pointer;
  }
}
