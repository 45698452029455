@import '../../variables';

.ReviewForm {
  h6.Heading {
    font-weight: 600;
    margin-bottom: 8px;
  }

  .Label {
    font-weight: 600;
    color: map-get($vintro-colors, secondary);
  }

  .Button--disabled {
    background: darken(map-get($vintro-colors, card-background), 10);
    color: #fff;
  }

  // TODO - Fix this at the star level
  .StarRating__star {
    margin-bottom: 0;
  }
}

.ReviewTeam {
  &__photo {
    width: 75px;
    height: 50px;
    border-radius: 4px;
  }

  &__photo_background {
    width: 75px;
    height: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 4px;
  }

  h5.Heading {
    margin: 0;
  }
}

.ReviewDetails {
  .Subheading {
    margin: 0 0 2px 0;
    font-weight: 500;
  }

  h5.Heading {
    margin: 2px 0 12px 0;
  }
}

.MyReview {
  @include breakpoint(small only) {
    .VideoPlayer {
      margin-bottom: 16px;
    }
  }

  .Modal {
    .Button {
      margin-right: 12px;
    }
  }
}
