@import '../../../../variables';

.LogoInputContainer {
  .help {
    font-family: proxima-nova, sans-serif;
    color: #052138;
    line-height: 1.4;
    font-size: 12px;
  }
}

.CroppingInputContainer {
  border: 1px dashed #c6cce4;
  margin: 10px 0;
  padding: 10px;
}

.CroppingInput {
  .help {
    font-family: proxima-nova, sans-serif;
    color: #052138;
    line-height: 1.4;
    font-size: 14px;
  }
}

.LogoInput {
  height: calc(140px + 16);
  margin: 16px 0 0 0;
  border: 1px dashed #c6cce4;
  padding: 16px;
  border-radius: 4px;
  position: relative;

  p {
    margin: 0;
    font-size: 14px;
  }

  input {
    appearance: none;
  }

  &__dropzone {
    width: auto;

    padding: 32px 40px;
    display: flex;
    border-radius: 4px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  &__container {
    display: flex;
    justify-items: center;
    align-items: center;
    height: 140px;
    width: 100%;
    text-align: center;
  }

  .Paragraph {
    font-size: 12px;
  }

  .Preview {
    position: relative;
    background: map-get($vintro-colors, card-background);
    width: 138px;
    height: 138px;
    border-radius: 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    .PreviewImage {
      width: 138px;
      height: 138px;
      background-size: contain;
      background-position: center;
      border-radius: 4px;
      background-repeat: no-repeat;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.Cropper {
  position: relative;
  width: 100%;
  height: 300px;
  // border: 1px dashed #c6cce4;
  margin: 10px 0;
  padding: 10px;

  div[data-testid='container'] {
    margin: 10px;
  }

  div[data-testid='cropper'] {
    // color: rgba(247, 249, 251, 0.8);
    color: rgba(71, 101, 133, 0.5);
  }
}

.CroppingControls {
  text-align: center;
  .Button {
    margin: 0 8px;
    font-size: 12px;
    // padding: 8px 8px;
  }
}

.Preview {
  div[data-testid='container'] {
    margin: 10px;
  }

  div[data-testid='cropper'] {
    color: rgba(255, 255, 255, 1);

    // color: map-get($vintro-colors, card-background);
  }
}

.LogoWrapper {
  position: relative;
}

.CropButton {
  position: absolute;

  z-index: 999;

  right: 10px;
  bottom: 10px;

  .Button {
    font-size: 11px;
    padding: 8px;
    margin-left: 8px;
  }
}
