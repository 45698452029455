@import '../../../variables';

.Badge {
  color: #fff;
  font-weight: bold;
  background: $primary-color;
  display: inline-flex;
  text-transform: uppercase;
  font-size: 11px;
  padding: 3px 7px;
  border-radius: $global-radius;
}
