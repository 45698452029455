@import '../../../variables';

.ChatMobileToggle {
  display: none;
  height: 40px;
  border: 1px solid $border-color;
  border-radius: 3px;
  padding: 0 10px;
  background: #fff;
  font-size: 13px;
  font-weight: 500;
  outline: 0;
  cursor: pointer;

  @include breakpoint(medium down) {
    display: inline-flex;
  }
}
