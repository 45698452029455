@import '../../../variables';

.PrimaryNav__container {
  position: sticky;
  top: 0;
  z-index: 99999;
}

.PrimaryNav {
  $c: &;
  height: 102px;
  margin-bottom: 60px;

  @include breakpoint(small only) {
    margin-bottom: 30px;
  }

  background: #fff;
  // box-shadow: 4px 4px 17px rgba(219, 219, 219, 0.5);
  position: relative;
  z-index: 9999;

  // box-shadow: 3px 3px 12px 0 rgba(23, 28, 33, 0.06);
  // border: 1px solid #f3f3f3;
  border-bottom: 3px solid $primary-color;

  &__submenu {
    padding: 0;
    margin: 0;
    list-style: none;
    display: none;
    position: absolute;
    top: 100%;
    background: #fff;
    border: 1px solid $border-color;
    border-radius: 3px;
    padding: 20px;
    width: 230px;
    right: -30px;
    z-index: 100;
  }

  &__submenuItem {
    margin-bottom: 10px;
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
  }

  &__item {
    display: inline-flex;
    font-weight: 600;
    font-size: 16px;
    margin-right: 20px;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    &:hover #{$c}__submenu {
      display: block;
    }

    &--callout {
      padding: 8px 12px;
      background: map-get($vintro-colors, primary);
      border-radius: 4px;

      .PrimaryNav__link {
        &:hover,
        &:visited,
        &:active {
          color: #fff;
        }

        &--active,
        &--active:hover,
        &--active:visited,
        &--active:active {
          color: #fff;
        }
      }
    }
  }

  &__cart {
    display: inline-flex;
    font-weight: 600;
    font-size: 16px;
    margin-right: 20px;
    padding: 8px 16px;
    border-radius: 4px;
    background: #333;

    justify-content: center;
    align-items: center;
    color: #fff;

    svg {
      width: 24px;
      height: 24px;
      fill: #fff;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    color: map-get($vintro-colors, secondary);
    font-size: 16px;
    font-family: proxima-nova, sans-serif;
    font-weight: bold;

    &:hover,
    &:visited,
    &:active {
      color: map-get($vintro-colors, secondary);
    }

    &--active,
    &--active:hover,
    &--active:visited,
    &--active:active {
      color: $primary-color;
    }

    &-text {
      cursor: pointer;
    }

    .Beacon {
      display: inline-block;
      margin: 0 2px;
      height: 10px;
      width: 10px;
      background: #ff5d5d;
      border-radius: 50%;
    }

    .BeaconAnimated {
      display: inline-block;
    }
  }

  &--mobile {
    height: 60px;

    #close {
      width: 24px;
      height: 24px;
      .a {
        fill: none;
        stroke: #476585;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 1.5px;
      }
    }

    #{$c}__item--callout {
      padding: 8px 0px;
      background: map-get($vintro-colors, primary);
      border-radius: 0px;

      #{$c}__link {
        background: map-get($vintro-colors, primary);
        &:hover,
        &:visited,
        &:active {
          color: #fff;
        }

        &--active,
        &--active:hover,
        &--active:visited,
        &--active:active {
          color: #fff;
        }
      }
    }

    #{$c}__list {
      display: block;
      background: #fff;
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      height: calc(100vh - 57px);
      overflow-y: scroll;
    }

    #{$c}__item {
      display: block;
      border-bottom: 1px solid $border-color;
      margin: 0;

      &:last-child {
        border-bottom: 0;
      }
    }

    #{$c}__link {
      display: block;
      background: #fff;
      padding: 15px 10px;
    }

    #{$c}__submenu {
      position: relative;
      width: 100%;
      display: block;
      border: none;
      padding: 0;
      right: initial;
      display: block;
    }

    #{$c}__submenuItem {
      margin-bottom: 0;
      border-top: 1px solid $border-color;

      #{$c}__link {
        background: #f5f5f5;
      }
    }

    #{$c}__menuToggle {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 32px;
      color: map-get($vintro-colors, secondary);
    }
  }

  button {
    outline: 0;
    cursor: pointer;
  }
}

.PrimaryNavCart {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  position: relative;

  span {
    margin: 0 4px;
  }

  &__details {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
  }
}

.PrimaryNav.PrimaryNav--mobile {
  .Controls {
    justify-content: center;
    display: flex;
    align-items: center;
  }
}
