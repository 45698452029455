@import '../../../variables';

.ChatSidebar {
  $c: &;

  background: map-get($vintro-colors, card-background);
  height: 100%;
  width: 300px;
  border-right: 1px solid map-get($vintro-colors, input-border);
  position: relative;

  &__filter {
    height: 80px;
    padding: 20px;
    border-bottom: 1px solid map-get($vintro-colors, input-border);

    @include breakpoint(large up) {
      display: none;
    }
  }

  &__filterInput[type='text'] {
    margin: 0;
    height: 40px;
    padding: 0;
    border-radius: 3px;
    padding: 10px;
    font-size: 14px;
  }

  &__content {
    position: relative;
    height: 100%;
  }

  &__messageList {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }

  @include breakpoint(medium down) {
    position: absolute;
    z-index: 2;
    left: -300px;
    transition: left 0.3s ease;
  }

  @include breakpoint(small only) {
    left: calc(-100% - 1px);
  }

  @include breakpoint(small only) {
    width: calc(100% + 1px);
  }

  &--active {
    @include breakpoint(medium down) {
      left: 0;
    }
  }
}
