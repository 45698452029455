@import '../../../variables';

.VideoPlayer {
  // background: lighten($light-gray, 4);
  // border: 2px solid $light-gray;
  border-radius: 4px;
  // width: 358px;
  // height: 640px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;

  width: 100%;

  .Play {
    position: relative;
    z-index: 3;
    cursor: pointer;

    svg {
      height: 80px;
      width: 80px;
      .a {
        fill: map-get($vintro-colors, primary);
        stroke: #fff;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 1.5px;
      }
    }
  }

  .Preview {
    .PreviewImage {
      width: 138px;
      height: 138px;
      background-size: contain;
      background-position: center;
      border-radius: 50%;
      background-repeat: no-repeat;
      pointer-events: none;

      position: absolute;
      top: 0;
      left: 0;
    }

    .LoadingMessage {
      width: 100%;
      display: inline-block;
      padding: 16px;
      font-family: Open Sans, sans-serif;
      color: #333;
      line-height: 1.4;
    }

    .Loader {
      display: inline-block;
    }

    video {
      padding: 0;
      position: absolute;
      left: 2px;
      top: 2px;
      // width: 358px;
      // height: 640px;
      margin: 0;
      object-fit: cover;
      border-radius: 4px;
      width: 100%;
    }
  }
}
